<template>
  <div class="relative">
    <div class="top-title pt-20 rounded relative">
      <div class="red-plus absolute -bottom-2 -left-2 w-4 h-4 z-20"></div>
      <div class="mx-auto">
        <div class="mx-8 px-4">
          <p class="font-mono text-lg base-font-gray-200 leading-none"> 
            Bucket > Channel 
          </p>
          <BigTitle title="Indigo Traveller" class="pt-3 pb-5 border-b border-grey-darker-300"/>
        </div>
      </div>
    </div>
    <div class="mx-auto pt-10 relative">
      <div class="mx-8">
        <div class="flex flex-wrap w-full mb-16">
          <div class="w-full xl:w-6/12 px-4">
            <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-4">Description</p>
            <p class="base-font-gray-200 text-2xl">
              Travelling misunderstood parts of the planet showing the human side of what we read in the headlines.
              Follow my adventures to lesser-known parts of the world with new videos every week, thank you for your time...
            </p>
          </div>
          <div class="flex flex-wrap w-full xl:w-6/12">
            <div class="flex flex-wrap w-full">
              <div class="w-full xl:w-1/2 px-4">
                <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-4">SUBSCRIBERS</p>
                <p class="base-font-gray-200 text-2xl pb-5 border-b border-dashed border-grey-darker-200">230 000</p>
              </div>
              <div class="w-full xl:w-1/2 px-4">
                <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-4">VIDEOS</p>
                <p class="base-font-gray-200 text-2xl pb-5 border-b border-dashed border-grey-darker-200">213</p>
              </div>
            </div>
            <div class="flex flex-wrap w-full border-b border-dashed border-grey-darker-200">
              <div class="w-full xl:w-1/2 px-4">
                <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-6 mt-6">ACTIVE SINCE</p>
                <p class="base-font-gray-200 text-2xl pb-5 leading-none">23rd of February 2019</p>
              </div>
              <div class="w-full xl:w-1/2 px-4">
                <p class="font-mono text-sm font-grey-darker-400 leading-none uppercase mb-4 mt-6">PRESENCE</p>
                <div class="pb-5">
                  <span class="base-bg-checkbox w-8 h-8 rounded-full inline-block mr-2"></span>
                  <span class="base-bg-checkbox w-8 h-8 rounded-full inline-block mr-2"></span>
                  <span class="base-bg-checkbox w-8 h-8 rounded-full inline-block"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-1/5 px-4 relative">
            <div class="red-vertical-line absolute -top-2 left-2 w-4 h-4 z-20"></div>
            <ul class="border-t border-grey-darker-200 sticky top-12">
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#videos'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Videos
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#uploadScheme'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Upload scheme
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#similarChannels'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Similar Channels
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#notes'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Notes
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#userEngagement'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  User Engagement
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#fandom'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Fandom
                </a>
              </li>
              <li class="border-b border-dashed">
                <a
                  href="#"
                  v-scroll-to="'#fandom'"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Narrative
                </a>
              </li>
            </ul>
          </div>
          <div class="w-4/5 px-4">
            <div id="videos">
              <NewestVideosLines :items="newest_videos">
                <template #title>
                  <Title :title="`Videos (${213})`" class="sticky top-12 pt-2 bg-white z-2">
                    <template #labelsSort>
                      <div>
                        <span class="yellow-bg-100 yellow-text text-xs px-2 py-1 cursor-pointer rounded-lg mr-3">Available</span>
                        <span class="base-bg base-font-gray-light-800 text-xs px-2 py-1 cursor-pointer rounded-lg">Removed</span>
                      </div>
                    </template>
                  </Title>
                </template>
              </NewestVideosLines>
            </div>

            <Pagination class="mb-16"/>

            <div id="uploadScheme" class="h-48">
              <Title 
                title="Uploads over time"
                class="w-full relative sticky top-12 pt-2 bg-white z-2"
                alighY="items-end"
              >
                <template #sort>
                  <span 
                    class="text-sm text-black flex cursor-pointer"
                    @click="setUploadsFilterList"
                  >
                    2020
                    <img v-if="isUploadsFilterList" src="../../assets/icons/chevron-bottom.svg" class="ml-4"/>
                    <img v-else src="../../assets/icons/chevron-top.svg" class="ml-4"/>
                  </span>
                  <ul 
                    v-if="isUploadsFilterList"
                    class="bg-grey rounded px-4 absolute right-0 top-full"
                  >
                    <li 
                      v-for="item in uploadsFilterList"
                      :key="item.value"
                      class="py-1 border-b border-dashed border-grey-lighter whitespace-nowrap cursor-pointer"
                      @click="setUploadsFilterList(item.value)"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </template>
              </Title>
            </div>

            <div id="notes">
              <Title title="Notes"  class="sticky top-12 pt-2 bg-white z-2"/>
              <NotesList :notes="videoNotes" class="mb-20"/>
            </div>
            
            <div id="similarChannels">
              <Title title="Similar Channels" class="sticky top-12 pt-2 bg-white z-2"/>
              <div class="flex flex-wrap justify-between mt-3">
                <div
                  v-for="(channel, index) in 9" 
                  :key="channel"
                  class="w-1/3 mb-8"
                  :class="{
                      'pr-8': (index + 1) % 3 != 0
                    }"
                >
                  <div class="flex w-full border rounded base-border-color py-3 pr-2 cursor-pointer">
                    <div class="w-1/4 flex items-center">
                      <img src="/images/channel-item.svg" alt="">
                    </div>
                    <div class="w-3/4">
                      <p class="text-sm text-black font-medium">Styxenhammer777</p>
                      <p class="text-super-xs base-font-gray-lighter mt-1">UCefl8NxGCklmljTRAtEaxag</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div id="userEngagement">
              <Title title="User Engagement" class="mt-6 sticky top-12 pt-2 bg-white z-2" />
              <div class="flex flex-wrap items-center justify-center w-full h-60 rounded border base-border-grey mt-3">
                <div class="text-center">
                  <p class="text-black text-xl w-full mb-4">This is a consultancy feature</p>
                  <router-link 
                    to='/'
                    class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-xs font-monoDemi font-medium"
                  >
                    Click here for more info
                  </router-link>
                </div>
              </div>
            </div>
            
            <div id="fandom">
              <Title title="Fandom" class="mt-6 sticky top-12 pt-2 bg-white z-2" />
              <div class="flex flex-wrap items-center justify-center w-full h-60 rounded border base-border-grey mt-3 mb-24">
                <div class="text-center">
                  <p class="text-black text-xl w-full mb-4">This is a consultancy feature</p>
                  <router-link 
                    to='/'
                    class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-xs font-monoDemi font-medium"
                  >
                    Click here for more info
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Pagination from '@/components/Pagination'
import NewestVideosLines from '@/components/BucketsBlocks/NewestVideosLines'
import Title from '@/components/Title'
import BigTitle from '@/components/BigTitle'
import NotesList from '@/components/NotesList'

export default {
  name: "Channels",
  components: {
    Pagination,
    NewestVideosLines, 
    Title, 
    BigTitle,
    NotesList
  },
  data(){
    return{
      isUploadsFilterList: false,
      uploadsFilter: '',
      uploadsFilterList: [
        {
          name: '2020',
          value: '2020'
        },
        {
          name: '2019',
          value: '2019'
        },
        {
          name: '2018',
          value: '2018'
        },
        {
          name: '2017',
          value: '2017'
        }
      ],
      newest_videos: [],
    }
  },
  computed: {
    ...mapGetters({
      videoNotes: 'videoDetails/videoNotes'
    })
  }, 
  methods: {
    ...mapActions({
      getDirectVideoNotes: 'videoDetails/getDirectVideoNotes',
    }),
    setUploadsFilterList(arg){
      if(arg){
        this.uploadsFilter = arg
      }
      this.isUploadsFilterList = !this.isUploadsFilterList
    }
  },
  async mounted(){
    console.log(this.$route)
    await this.getDirectVideoNotes({ 
      id: '2L7yLB3P8Xg', 
      limit: 20 // doesn`t work now
    })
    let newest_videos =  await this.$axios.get('/dummy-data/buckets/private_videos.json')
    this.newest_videos =  newest_videos.data
  }
}
</script>

<style lang="scss" scoped>
.white-line{
  top: calc(2rem - 1px);
  background-color: #F6F6F6;
}
.top-title{
  background-color: #EFEFEF;
}
.bucket-admin-wrap{
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.11);
}
</style>