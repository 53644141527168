<template>
  <div class="flex flex-wrap items-center justify-center w-full mt-5">
    <span class="flex items-center justify-center w-8 h-8 base-font-gray-light-700 border-t border-b border-l border-r border-grey-darker-100 paginationItem cursor-pointer">
      <chevronLeft />
    </span>
    <ul class="flex flex-wrap">
      <li
        v-for="item in 5"
        :key="item"
        class="flex items-center justify-center w-8 h-8 font-mono text-sm base-font-gray-light-400 border-t border-b border-r border-grey-darker-100 paginationItem cursor-pointer"
      > 
        {{ item }}
      </li>
    </ul>
    <span class="flex items-center justify-center w-8 h-8 base-font-gray-light-700 border-t border-b border-r border-grey-darker-100 paginationItem cursor-pointer">
      <chevronRight />
    </span>
  </div>
</template>

<script>
  import chevronLeft from '@/components/icons/chevron-left'
  import chevronRight from '@/components/icons/chevron-right'

  export default {
    name: 'Pagination',
    components: {
      chevronLeft,
      chevronRight
    },
  }
</script>

<style lang="scss" scoped>
.paginationItem{
  &:hover{
    background-color: #F8F6FF;
    border-color: #DCD3FF;
    color: #4F23FF;
  }
}
</style>