<template>
  <div class="w-full">
    <slot name="title"></slot>
    <ul class="videos">
      <li 
        v-for="video in items"
        :key="video.video_id"
        class="border-dashed overflow-hidden border-b bucket-line flex items-center"
      >
        <span class="bg-cover my-2 bg-center block rounded" :style="`background-image: url('https://i3.ytimg.com/vi/${video.videoId}/default.jpg'); background-size: 100%; width: 90px; height: 45px;`">

        </span>
        <!-- <img :src="`https://i3.ytimg.com/vi/${video.videoId}/default.jpg`" /> -->
        <a :href="`https://www.youtube.com/watch?v=${video.videoId}`" target="_blank" class="contents">
        <span class="text-base base-font-gray-200 py-3 pr-4 w-1/2 whitespace-nowrap relative ml-4">
          <span class="font-mono text-sm">{{video.channelName}}</span> <br/>
          {{ video.title == "" ? video.videoId : video.title }}
          <!-- <span class="absolute w-1/2 h-full right-0 top-0 gradient"></span> -->
        </span>
        </a>
        <span class="font-mono text-sm w-1/4 py-3 pr-3 time text-right ml-auto">{{ formatDate(video.publishedAt*1000) }} ago</span>
      </li>
    </ul>

    <button 
      v-if="isCreate"
      class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium mt-4 ml-4"
      @click="createNewBucket"
    > 
      Create a New Bucket 
    </button>
  </div>
</template>

<script>
  import { formatDate } from '@/helpers/formats'

  export default {
    props: [ 'items', 'isCreate' ], 
    methods: {
      createNewBucket(){
        this.$emit('createNewBucket')
      },
      formatDate: formatDate
    },
  }
</script>

<style lang="scss" scoped>
.bucket-line{
  &:hover{
    background-color: #F9F9F9;
    .time{
      background-color: #F9F9F9;
    }
    .gradient{
      background: linear-gradient(269.36deg, #F9F9F9 35.1%, rgba(255, 255, 255, 0) 63.46%);;
    }
  }
  .time{
    background-color: white;
    z-index: 1;
  }
  .gradient{
    background: linear-gradient(269.36deg, #FFFFFF 35.1%, rgba(255, 255, 255, 0) 63.46%);
  }
}

.videos {
  max-height: 400px;
  overflow: scroll;
}
</style>