<template>
  <ul class="w-full">
    <li 
      v-for="note in notes"
      :key="note.noteId"
      class="flex flex-wrap items-center w-full"
    >
      <div class="flex flex-wrap items-center w-full savedVideoLine px-4 py-4 border-b base-border-grey purple-light-b1-100">
        <div class="flex items-center mr-4">
          <img src="../assets/images/savedVideoIcon.png" class="-mr-7 z-1"/>
          <span class="text-base text-black pl-8 pr-2 purple-light-bg-200 rounded">{{ 'Eddy Kreutz dsdfsdfsdfsdf sdf sdf ' | truncate(13) }}</span>
        </div>
        <p class="block base-font-gray-200 text-base">
          Makin’ Moves - Ep. 14 - Movin’ With The Shark
        </p>
      </div>
      <ul class="w-full">
        <li class="px-4 py-3 border-b border-dashed border-grey-darker-200">
          <p class="font-mono text-sm base-font-gray-light-400 mb-1">{{ note.author }} wrote on {{ $moment.unix(note.date).format('DD/MM/YYYY HH:mm') }}</p>
          <span 
            v-for="(message, index) in note.note"
            :key="`${index}message`"
            class="text-base base-font-gray-300"
          >
            <openVideoOnTime 
              v-if="message.time"
              :time="message.text"
              class="text-black"
            /> 
            <span v-else> {{ message.text }} </span>
          </span>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import openVideoOnTime from '@/components/video-detail/openVideoOnTime'

  export default {
    name: 'NotesList',
    components: {
      openVideoOnTime
    },
    props: {
      notes: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>